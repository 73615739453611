// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authentication--UUSDo{background:no-repeat center center fixed;background-size:cover;display:flex;flex-direction:column;justify-content:center;min-height:100vh}.authentication--UUSDo .box--O7vnI{width:335px;margin:auto;box-shadow:0 18px 78px -12px rgba(0,0,0,.5)}.authentication--UUSDo .box--O7vnI .logo--cNsLL{margin-bottom:30px;text-align:center}.authentication--UUSDo .box--O7vnI .logo--cNsLL .logo-image--Aq6hs{max-width:265px}.authentication--UUSDo .footer--zwnbU{height:50px;width:100%;color:rgba(0,0,0,.88)}.authentication--UUSDo .footer--zwnbU .link--L45Iv{color:rgba(0,0,0,.88)}.authentication--UUSDo .footer--zwnbU .link--L45Iv:hover,.authentication--UUSDo .footer--zwnbU .link--L45Iv:active{color:rgb(68.6787234043,162.285106383,231.8212765957)}`, "",{"version":3,"sources":["webpack://./app/Frontend/Applications/Authentication/AuthenticationApp/AuthenticationLayout/AuthenticationLayout.module.scss","webpack://./app/Shared/resources/assets/app/sass/design-implementation.scss"],"names":[],"mappings":"AAGA,uBACI,wCAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,gBAAA,CAEA,mCACI,WAAA,CACA,WAAA,CACA,2CAAA,CAEA,gDACI,kBAAA,CACA,iBAAA,CAEA,mEACI,eAAA,CAUZ,sCACI,WAAA,CACA,UAAA,CACA,qBCZU,CDcV,mDACI,qBCfM,CDiBN,mHAEI,qDAAA","sourcesContent":[".authentication{background:no-repeat center center fixed;background-size:cover;display:flex;flex-direction:column;justify-content:center;min-height:100vh}.authentication .box{width:335px;margin:auto;box-shadow:0 18px 78px -12px rgba(0,0,0,.5)}.authentication .box .logo{margin-bottom:30px;text-align:center}.authentication .box .logo .logo-image{max-width:265px}.authentication .footer{height:50px;width:100%;color:rgba(0,0,0,.88)}.authentication .footer .link{color:rgba(0,0,0,.88)}.authentication .footer .link:hover,.authentication .footer .link:active{color:rgb(68.6787234043,162.285106383,231.8212765957)}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authentication": `authentication--UUSDo`,
	"box": `box--O7vnI`,
	"logo": `logo--cNsLL`,
	"logoImage": `logo-image--Aq6hs`,
	"footer": `footer--zwnbU`,
	"link": `link--L45Iv`
};
export default ___CSS_LOADER_EXPORT___;
