// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher-container--oAjkZ{position:absolute;right:30px;top:30px;background:#fff;border-radius:6px;box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09);padding:1.2em;text-align:center}`, "",{"version":3,"sources":["webpack://./app/Frontend/Applications/Authentication/AuthenticationApp/AuthenticationLayout/LanguageSwitcher/LanguageSwitcher.module.scss"],"names":[],"mappings":"AAAA,oCACI,iBAAA,CACA,UAAA,CACA,QAAA,CACA,eAAA,CACA,iBAAA,CACA,oGAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".language-switcher-container{position:absolute;right:30px;top:30px;background:#fff;border-radius:6px;box-shadow:0 1px 2px -2px rgba(0,0,0,.16),0 3px 6px 0 rgba(0,0,0,.12),0 5px 12px 4px rgba(0,0,0,.09);padding:1.2em;text-align:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSwitcherContainer": `language-switcher-container--oAjkZ`
};
export default ___CSS_LOADER_EXPORT___;
