// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown--oBAI2{font-weight:bold;text-align:center;color:#2e97e5;padding:5px 0 10px 0}`, "",{"version":3,"sources":["webpack://./app/Frontend/Applications/Authentication/AuthenticationApp/AuthenticationLayout/TwoFactorAuthentication/TwoFactorAuthentication.module.scss","webpack://./app/Shared/resources/assets/app/sass/design-definition.scss"],"names":[],"mappings":"AAEA,kBACI,gBAAA,CACA,iBAAA,CACA,aCmCY,CDlCZ,oBAAA","sourcesContent":[".countdown{font-weight:bold;text-align:center;color:#2e97e5;padding:5px 0 10px 0}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdown": `countdown--oBAI2`
};
export default ___CSS_LOADER_EXPORT___;
