/**
 * Prevents the authentication from being displayed in an iFrame.
 *
 * Sometimes it happens that a session expires and the user opens an iFrame (for example in a popup) without
 * actually reloading the main page. The iFrame will not load it's content but get redirected to the
 * authentication page which is then displayed in the iFrame.
 */ export var preventLoadingInIFrame = function() {
    var inIFrame;
    // Browsers can block access to window.top due to same origin policy.
    // IE bugs also take place.
    try {
        inIFrame = window.self !== window.top;
    } catch (e) {
        inIFrame = true;
    }
    if (inIFrame) {
        // Since reloading the top window may fail or be slow, we'll just hide the contents of the iFrame
        document.body.style = 'background: none !important; visibility: hidden';
        // Will redirect to authentication page
        window.top.location.reload();
    }
};
